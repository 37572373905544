<template>
  <div class="content-wrap">
    <div class="con_box">
      <div class="content-title">
        <div>
          <div class="content-items">
            <label>结算单号：</label>
            <span>-</span>
          </div>
          <div class="content-items">
            <label>单据日期：</label>
            <span>-</span>
          </div>
          <div class="content-items">
            <label>收票状态：</label>
            <span>-</span>
          </div>
          <div class="content-items">
            <label>开票状态：</label>
            <span>-</span>
          </div>
          <div class="content-items">
            <label>上传状态：</label>
            <span>-</span>
          </div>
        </div>
        <el-button type="primary" @click="handleClick">开票计划</el-button>
      </div>
      <div class="contents">
        <div class="item">
          <label>购货方名称：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>购货方税号：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>购货方企业地址、电话：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>购货方开户行及账号：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>结算主体：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>供应商税号：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>供应商编码、名称：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>供应商应开票金额：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>结算应付总金额：</label>
          <span>-</span>
        </div>
        <div class="item">
          <label>回写响应报文：</label>
          <span>-</span>
        </div>
      </div>
    </div>

    <div class="table">
      <!-- <p-header title="商品明细"></p-header> -->
      <el-table :data="tableData" stripe border :header-cell-style="handleHeaderCellStyle" style="width: 100%">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="merchandiseUniqueCode" label="商品编码" width="150"></el-table-column>
        <el-table-column prop="merchandiseName" label="商品名称"></el-table-column>
        <el-table-column prop="specification" label="规格型号" width="150"></el-table-column>
        <el-table-column prop="unit" label="商品单位" width="100"></el-table-column>
        <el-table-column prop="quantity" label="数量" width="100" align="right" header-align="center"></el-table-column>
        <el-table-column prop="price" label="单价" width="120" align="right" header-align="center" />
        <el-table-column prop="amount" label="金额" width="120" align="right" header-align="center" :formatter="handleTableValueFormat" />
        <el-table-column prop="taxRate" label="税率" width="100" align="right" header-align="center" :formatter="handleTableValueFormat" />
        <el-table-column prop="taxAmount" label="税额" width="120" align="right" header-align="center" :formatter="handleTableValueFormat" />
        <el-table-column prop="sumAmount" label="价税合计" width="120" align="right" header-align="center" :formatter="handleTableValueFormat" />
      </el-table>
      <div class="table-statistics">
        <div>合计</div>
        <div>金额：-</div>
        <div>税额：-</div>
        <div>价税合计：-</div>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next" :current-page.sync="page.current" :page-sizes="pageSizes" :page-size="page.size" :total="page.total"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { tableStyle, pagingMethods, changeMethods } from '@/util/mixins';

export default {
  name: 'List',
  mixins: [tableStyle, pagingMethods, changeMethods],

  data() {
    return {
      pageSizes: [],
      tableData: [{}, {}],
      page: {}
    };
  },
  created() {},
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '税率':
          return value + '%';
        default:
          return this.formatMoney(value);
      }
    },
    handleClick() {
      const routeData = this.$router.resolve({ path: '/share-settlement-detail' });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.content-wrap {
  display: flex;
  flex-direction: column;
  background-color: #eaeff8;
  .con_box {
    background: #fff;
    margin: 20px;
    padding: 20px;
  }

  .content-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 24px;
    height: 60px;
    font-size: 16px;
    background-color: #ebf4ff;

    label {
      color: #666666;
    }

    span {
      margin-right: 60px;
      color: #333333;
    }
    div {
      display: flex;
    }
  }
  .table-statistics {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: left;
    background: #ebf4ff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }

  .contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    flex-wrap: wrap;
    padding: 20px;
    background: #fff;
    label {
      color: #666666;
    }

    span {
      margin-right: 44px;
      color: #333333;
    }

    .item {
      width: 33.33%;
      margin-bottom: 16px;

      label {
        font-size: 14px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .table {
    padding: 20px;
    margin: 0 20px;
    background: #fff;
  }
}
</style>
